@font-face {
  font-family: 'Philosopher';
  src: local('Philosopher'), url(./assets/Philosopher-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Open Sans Light';
  src: local('Open Sans Light'), url(./assets/OpenSans.woff) format('woff');
}

body, html {
  margin: 0;
  padding: 0;
}

.outer-container {
  background: #111;
  display: inline-block;
  width: 100%;
  min-height: 100vh;
}

.container {
  max-width: 480px;
  margin: auto;
  background: #222;
  padding: 48px;
  box-shadow: 0px 0px 24px 4px #b9b2681f;
}

.logo-container {
  width: 240px;
  margin: auto;
}

.logo {
  width: 240px;
  filter: invert(1);
}

h1 {
  font-family: 'Philosopher' !important;
  font-size: 36px;
  color: #fff;
  margin-bottom: 0;
  text-align: center;
  border-bottom: 2px dotted #cacaca;
  padding-bottom: 18px;
}

h4 {
  font-family: 'Philosopher' !important;
  color: #fff264;
  font-size: 24px;
  margin-top: 21px;
  margin-bottom: 0;
}

h6 {
  font-family: 'Philosopher' !important;
  font-size: 24px;
  color: #fff264;
  margin-top: 0px;
}

p {
  font-family: 'Open Sans Light';
  line-height: 1.5rem;
  font-size: 14px;
  color: #fff;
}

.poster-container {
  width: 100%;
}

.donation-button-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.donation-button {
  background:  #fff264;
  font-size: 1rem;
  border: none;
  margin: 2px;
  padding: 4px 1rem;
  font-weight: 700;
    font-family: 'Philosopher' !important;
    cursor: pointer;
    margin-bottom: 0.666rem;
}

.donation-button.custom-donation-button {
  padding: 0px 4px;
  height: 40px;
  /* background: #ff0; */
  margin-left: 0.5em;
  margin-top: 11px;
}

.poster {
  width: 100%;
  /* padding: 24px; */
  height: auto;
  margin-top: 2rem;
}

@media only screen and (max-width: 480px) {
  .poster-container {
      padding: 0 2rem;
      box-sizing: border-box;
  }

  h1 {
    font-size: 28px;
  }

  .container {
    padding: 14px;
  }
}
