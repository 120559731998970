input,
.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  width: calc(100% - 30px);
  padding: 10px 14px;
  font-size: 1em;
  font-family: "Source Code Pro", monospace;
  /* box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
    rgba(0, 0, 0, 0.0196078) 0px 1px 0px; */
  /* border-bottom: 2px dashed #937c4c; */
  outline: 0;
  /* border-radius: 4px; */
  /* color: #fff !important; */

  background: white;
}

.payment-btn {
  width: 100% !important;
  background: #fff264 !important;
  color: #000 !important;
}

.MuiInputLabel-shrink {
  color: #fff !important;
}
