.modal-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: #000e;
  z-index: 9;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}

.modal-inner-container {
  border-radius: 12px;
    /* border: 1px solid #1F3C82; */
  /* box-shadow: 0px 2px 2px 2px rgba(200,200,200,1); */
  background: #222;
  min-height: 420px;
  width: 96%;
  max-width: 340px;
  transition: 0.5s;
  position: relative;
}

.modal-close-button {
  position: absolute;
  color: #000;
  right: 10px;
  top: 10px;
  width: 16px;
  height: 16px;
  transform: scaleX(1.25);
  cursor: pointer;
  z-index: 9;
  filter: invert(1)
    /* filter: invert(19%) sepia(63%) saturate(957%) hue-rotate(204deg) brightness(97%) contrast(90%); */
}

.modal-closed .modal-inner-container {
  transform: translate(0 100vh);
}

.modal-open .modal-inner-container {
  transform: translate(0 0);
}

.modal-closed {
  opacity: 0;
  z-index: -1;
}

.modal-open {
  top: 0;
  opacity: 1;
}

.graph-animation-container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  position: absolute;
  /* border: 1px solid #0ff; */
  height: 96px;
  bottom: -2px;
  left: 0;
  z-index: 999;
    transform: rotate(180deg);
}

.graph-animation-bar {
  /* height: 200px; */
  height: 0;
  width: 100%;
  /* background: #DDEAFD; */
  border: 1px solid #1F3C8266;
  transition: 0.5s;
  mix-blend-mode: color-burn;
  /* background: #DDEAFD; */
  border-radius: 2px;
  /* border: 1px solid #fffa; */

  /* background: #f00; */
}

.graph-animation-container .graph-animation-bar:nth-child(4){
  /* background: #DDEAFD !important; */
}

.graph-animation-container:last-of-type .active-bar {
  /* animation: bar 4s ease-in-out 7s infinite; */
}

.graph-animation-container:last-of-type .graph-animation-bar {
  display: none;
}

.graph-animation-container:last-of-type .graph-animation-bar {
  background: #DDEAFD88;
  /* border: none; */
  /* display: none; */

}

.active-bar {
  height: 0;
  animation: bar 4s ease-in-out ;
}

@keyframes bar {
  0% {
    height: 0;
  }

  50% {
    height: 88px;
  }

  100% {
    height: 0;
  }
}
